<template>
  <div class="favq clearfix">
     <headtopsystemgl></headtopsystemgl>
    <div class="enterprise clearfix">
      <div class="left">
        <systemglleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">局内角色权限配置</h1>
          <el-popover placement="bottom" width="320" trigger="click" ref="popnew">
            <ul class="xinzeng_xiajitc">
              <li>
                <span>角色名称：</span>
                <el-input v-model="role_name" placeholder="请输入"></el-input>
              </li>
              <li>
                <span>层级：</span>
                <el-select placeholder="请选择" v-model="tier">
                  <el-option label="1" value="1"></el-option>
                  <el-option label="2" value="2"></el-option>
                  <el-option label="3" value="3"></el-option>
                </el-select>
              </li>
            </ul>
            <div class="tc_tit">权限配置</div>
            <ul class="xinzeng_jgoutc">
              <li>招商项目管理</li>
              <div class="quanx_check">
                <span>
                  <el-checkbox v-model="check1"></el-checkbox>调度
                </span>
              </div>
              <li>项目上报追踪</li>
              <div class="quanx_check">
                <span>
                  <el-checkbox v-model="check2"></el-checkbox>审核
                </span>
                <span>
                  <el-checkbox v-model="check3"></el-checkbox>删除
                </span>
              </div>
              <li>区域要素管理</li>
              <div class="quanx_check">
                <span>
                  <el-checkbox v-model="check4"></el-checkbox>审核/上报
                </span>
                <span>
                  <el-checkbox v-model="check5"></el-checkbox>新增/编辑
                </span>
              </div>
            </ul>
            <ul class="xinzeng_xiajitc">
              <li>
                <span>角色描述：</span>
                <el-input type="textarea" placeholder="请输入内容" v-model="info"></el-input>
              </li>
            </ul>
            <div class="btn">
              <el-button id="but2" @click="close">取消</el-button>
              <el-button id="but1" @click="addRole()">确定</el-button>
            </div>
            <el-button class="zuzhi_btns" slot="reference">+新增角色</el-button>
          </el-popover>
        </div>

        <div class="project_main">
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:12%;">职务角色</td>
                <td style="width:27%;">权限</td>
                <td style="text-align:left;">说明</td>
              </tr>
              <tr v-for="(item,index) in listdataroles" :key="index">
                <td>{{item.name}}</td>
                <td>
                  <el-popover placement="bottom" width="320" trigger="click" :ref="'popbj'+item.id" @show="getRole(item.id)" @hide="hidedata">
                    <ul class="xinzeng_xiajitc">
                      <li>
                        <span>角色名称：</span>
                        <el-input v-model="role_name" placeholder="请输入"></el-input>
                      </li>
                      <li>
                        <span>层级：</span>
                        <el-select placeholder="请选择" v-model="tier">
                          <el-option label="1" value="1"></el-option>
                          <el-option label="2" value="2"></el-option>
                          <el-option label="3" value="3"></el-option>
                        </el-select>
                      </li>
                    </ul>
                    <div class="tc_tit">权限配置</div>
                    <ul class="xinzeng_jgoutc">
                      <li>招商项目管理</li>
                      <div class="quanx_check">
                        <span>
                          <el-checkbox v-model="check1"></el-checkbox>调度
                        </span>
                      </div>
                      <li>项目上报追踪</li>
                      <div class="quanx_check">
                        <span>
                          <el-checkbox v-model="check2"></el-checkbox>审核
                        </span>
                        <span>
                          <el-checkbox v-model="check3"></el-checkbox>删除
                        </span>
                      </div>
                      <li>区域要素管理</li>
                      <div class="quanx_check">
                        <span>
                          <el-checkbox v-model="check4"></el-checkbox>审核/上报
                        </span>
                        <span>
                          <el-checkbox v-model="check5"></el-checkbox>新增/编辑
                        </span>
                      </div>
                    </ul>
                    <ul class="xinzeng_xiajitc">
                      <li>
                        <span>角色描述：</span>
                        <el-input type="textarea" placeholder="请输入内容" v-model="info"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <el-button id="but2" @click="close2(item.id)">取消</el-button>
                      <el-button id="but1" @click="updateRole(item.id)">确定</el-button>
                    </div>

                    <el-button class="bluezi_btn" slot="reference">编辑</el-button>
                  </el-popover>
                  <el-popover placement="bottom" width="220" trigger="click" :ref="'popsc'+item.id">
                    <p class="delt_p">确定删除此角色？</p>
                    <div class="btn">
                      <el-button id="but2">取消</el-button>
                      <el-button id="but1" @click="deleteRole(item.id)">确定</el-button>
                    </div>

                    <el-button class="huangzi_btn" slot="reference">删除</el-button>
                  </el-popover>
                </td>
                <td style="text-align:left;">{{item.info}}</td>
              </tr>
            </table>
          </div>

          <!-- <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>-->
        </div>

        <div class="soso_box">
          <h1 class="zhaosh_tit">新增管理员角色</h1>
          <el-popover placement="bottom" width="320" trigger="click" ref="men">
            <ul class="xinzeng_xiajitc">
              <li>
                <span>账号：</span>
                <el-input v-model="account" placeholder="请输入"></el-input>
              </li>
              <li>
                <span>密码</span>
                <el-input v-model="password" type="password" placeholder="请输入"></el-input>
              </li>
              <li>
                <span>角色分配</span>
                <el-select placeholder="管理员" v-model="role">
                  <el-option label="超级管理员" value="1"></el-option>
                  <el-option label="系统运维员" value="2"></el-option>
                </el-select>
              </li>
            </ul>
            <div class="btn">
              <el-button id="but2" @click="close3">取消</el-button>
              <el-button id="but1" @click="addManager()">确定</el-button>
            </div>
            <el-button class="zuzhi_btns" slot="reference">+新增管理员</el-button>
          </el-popover>
        </div>

        <div class="project_main">
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:22%;">职务角色</td>
                <td style="text-align:left;">说明</td>
              </tr>
              <tr>
                <td>超级管理员</td>
                <td style="text-align:left;">拥有所有权限</td>
              </tr>
              <tr>
                <td>系统运维员</td>
                <td style="text-align:left;">组织架构管理、注册用户管理</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  roles,
  addRole,
  updateRole,
  deleteRole,
  addManager,
  getRole
} from "@/api/index";
export default {
  data() {
    return {
      listdataroles: [],
      tier: "",
      check1: 0,
      check2: 0,
      check3: 0,
      check4: 0,
      check5: 0,
      role_name: "", // 角色名
      rolev: "", //   权值
      info: "", //  角色描述
      account: "", //")  # 账户名-字母开头
      password: "", //")  # 密码
      role: "" //")  # 角色 1超级管理员，2系统运维员
    };
  },
  created() {
    this.getroles();
  },
  methods: {
    async getroles() {
      const res = await roles();
      this.listdataroles = res;
    },
    async addRole() {
      if (this.check1 == true) {
        var check1 = 1;
      } else {
        var check1 = 0;
      }
      if (this.check2 == true) {
        var check2 = 1;
      } else {
        var check2 = 0;
      }
      if (this.check3 == true) {
        var check3 = 1;
      } else {
        var check3 = 0;
      }
      if (this.check4 == true) {
        var check4 = 1;
      } else {
        var check4 = 0;
      }
      if (this.check5 == true) {
        var check5 = 1;
      } else {
        var check5 = 0;
      }
      var rolev = this.tier + check1 + check2 + check3 + check4 + check5;
      const res = await addRole({
        role_name: this.role_name, // 角色名
        rolev: rolev, //   权值
        info: this.info //  角色描述
      });
      let refName2 = "popnew";
      this.$refs[refName2].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getroles();
        this.tier = "";
        this.check1 = 0;
        this.check2 = 0;
        this.check3 = 0;
        this.check4 = 0;
        this.check5 = 0;
        this.info = "";
        this.role_name = "";
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    async updateRole(id) {
      if (this.check1 == true) {
        var check1 = 1;
      } else {
        var check1 = 0;
      }
      if (this.check2 == true) {
        var check2 = 1;
      } else {
        var check2 = 0;
      }
      if (this.check3 == true) {
        var check3 = 1;
      } else {
        var check3 = 0;
      }
      if (this.check4 == true) {
        var check4 = 1;
      } else {
        var check4 = 0;
      }
      if (this.check5 == true) {
        var check5 = 1;
      } else {
        var check5 = 0;
      }
      var rolev = this.tier + check1 + check2 + check3 + check4 + check5;
      const res = await updateRole({
        id: id,
        role_name: this.role_name, // 角色名
        rolev: rolev, //   权值
        info: this.info //  角色描述
      });
      let refName2 = "popbj" + id;
      this.$refs[refName2][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getroles();
        this.tier = "";
        this.check1 = 0;
        this.check2 = 0;
        this.check3 = 0;
        this.check4 = 0;
        this.check5 = 0;
        this.info = "";
        this.role_name = "";
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    close() {
      let refName2 = "popnew";
      this.$refs[refName2].doClose();
    },
    close2(id) {
      let refName2 = "popbj" + id;
      this.$refs[refName2][0].doClose();
    },
    close3() {
      let refName2 = "men";
      this.$refs[refName2].doClose();
    },
    async deleteRole(id) {
      const res = await deleteRole({
        id: id
      });
      let refName2 = "popsc" + id;
      this.$refs[refName2][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getroles();
        1;
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    async addManager() {
      const res = await addManager({
        account: this.account, //")  # 账户名-字母开头
        password: this.password, //")  # 密码
        role: this.role //")  # 角色 1超级管理员，2系统运维员
      });
      let refName2 = "men";
      this.$refs[refName2].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getroles();
        1;
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    async getRole(id){
      const res = await getRole({
        id:id
      })
      this.role_name = res.name
      var arr = res.rolev.split('')
      this.tier = arr[0]
      if (arr[1] == 1) {
        this.check1 = true;
      } else {
        this.check1 = false;
      }
      if (arr[2] == 1) {
        this.check2 = true;
      } else {
        this.check2 = false;
      }
      if (arr[3] == 1) {
         this.check3 = true;
      } else {
        this.check3 = false;
      }
      if (arr[4] == 1) {
        this.check4 = true;
      } else {
        this.check4 = false;
      }
      if (arr[5] == 1) {
        this.check5 = true;
      } else {
        this.check5 = false;
      }
    },
    hidedata(){
      this.role_name=""
      this.tier=""
      this.check1 =""
      this.check2 =""
      this.check3 =""
      this.check4 =""
      this.check5 =""
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.zuzhi_btns {
  display: inline-block;
  float: left;
  padding: 0 15px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 2% 0 1% 0;
  cursor: pointer;
}

.zhaosh_tit {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}
.soso_box {
  padding: 22px 10px 0 22px;
  overflow: hidden;
  zoom: 1;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 15px);
  min-height: 89vh;
  float: right;
  margin-left: 10px;

  table td {
    font-size: 14px;
    line-height: 44px;
  }
  .project_main {
    padding: 10px 22px;
    min-height: 80%;
    overflow: hidden;
    zoom: 1;

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 3%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
        cursor: pointer;
      }
      .zizi_btn {
        color: #135de6;
        cursor: pointer;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
        cursor: pointer;
      }
      .huangzi_btn {
        color: #e69554;
        cursor: pointer;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }
    .project_tables td:first-child {
      text-align: left;
      padding-left: 60px;
    }
    .project_tables td {
      border-bottom: 1px solid #eee;
    }
    .project_tables td img {
      margin-right: 5px;
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 5px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}

.dispatch {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  z-index: 99;
  margin-top: 15px;
  ul {
    width: 100%;
    height: 138px;
    padding: 10px;
    overflow: auto;
    li {
      list-style: none;
      cursor: pointer;
      height: 30px;
      line-height: 20px;
      width: 100%;
      padding: 5px;
      &:hover {
        background-color: #eee;
        color: #e69554;
      }
      &.active {
        color: #e69554;
        background-color: #eee;
      }
    }
  }
}
.btn {
  text-align: center;
  margin: 20px 0 15px 0;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.branch {
  overflow: auto;
  ul {
    height: auto;
  }
}
.el-collapse-item {
  padding: 0 10px;
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}

.xinzeng_xiajitc {
  margin-top: 5px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li {
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li span {
  display: block;
  width: 82px;
  float: left;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.xinzeng_xiajitc li .el-input {
  width: 206px;
}
.xinzeng_xiajitc li .el-select {
  width: 206px;
}
.xinzeng_xiajitc li .el-textarea {
  width: 206px;
}
.tc_tit {
  margin-bottom: 5px;
}
.xinzeng_jgoutc {
  line-height: 34px;
}
.xinzeng_jgoutc li {
  list-style: none;
}
.xinzeng_jgoutc .el-checkbox {
  margin-right: 5px;
}
.delt_p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 25px;
}

.quanx_check {
  margin-left: 20px;
}
.quanx_check span {
  margin-right: 25px;
}

/deep/.el-textarea__inner:focus {
  border-color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fe6a00;
  border-color: #fe6a00;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
/deep/.el-collapse-item__arrow {
  display: none;
}
/deep/.el-button {
  border: 0;
}
/deep/.el-input {
  width: 100%;
  height: 30px;
  input {
    border-radius: 0;
    width: 100%;
    height: 30px;
    color: #333;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
  /deep/.el-icon-circle-close {
    background: url("../../assets/merchantsradar/cuo.png") 50% 50% no-repeat;
  }
  .el-icon-circle-close:before {
    content: "";
  }
}
/deep/.el-select {
  width: 70%;
  height: 30px;
  input {
    width: 100%;
    height: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  /deep/.el-input__icon {
    line-height: 30px !important;
  }
}
/deep/ .el-checkbox__label {
  display: none;
}
</style>